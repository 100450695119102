import { AppFlavor } from "../models";
export const BASE_APP_FLAVOR: AppFlavor = {
  id: "MOC",
  theme: {
    admin_index: 0,
    user_index: undefined,
    schemes: [
      {
        p: "#112836",
        paper: "#f2f2f2",
        pt: "#ffffff",
        a: "#fb7185",
        at: "#191A1F",
        page: "#ffffff",
        paget: "#191A1F",
        error: "red",
        info: "rgba(0,50,255,0.8)",
        hint: "#4882C6",
        success: "rgb(0,200,0)",
        warn: "orange",
        skeleton: "#D9D9D946",
        highlight: "#E95B42",
        cover: "#285180",
        "n-200": "#96B8DE",
        "n-50": "#E4ECF7",
        "n-700": "#1C3859",
        disabled: "#00000033",
        input: {
          bg: "#D9D9D946",
          text: "#191A1F",
          touched: "rgba(0,50,255, 0.5)",
          placeholder: "#191A1F50",
        },
        button: {
          bg: "#fb7185",
          text: "#ffffff",
          // touched: "rgba(0,50,255, 0.5)",
          // disabled: "rgba(0,50,255, 0.1)",
        },
        label: {
          text: "#191A1F",
        },
        alert: {
          bg: "#191A1F",
          text: "#ffffff",
          error: "red",
          info: "rgba(0,50,255,0.8)",
          success: "rgb(0,200,0)",
          warn: "orange",
        },
        "nav-top": {
          bg: "#112836",
          bga: "",
          text: "#ffffff",
          texta: "",
        },
        "nav-side": {
          bg: "#f2f2f2",
          bga: "#E95B42",
          text: "#112836",
          texta: "#FFFFFF",
          divider: "#BDD2EA",
        },
        select: {
          bg: "#FFFFFF",
          bga: "#FFFFFF",
          bgh: "",
          text: "#000000",
          texta: "#000000",
          texth: "",
        },
      },
      // {
      //   p: "#0D2937",
      //   pt: "#ffffff",
      //   a: "#EF6346",
      //   at: "#191A1F",
      //   page: "#ffffff",
      //   paget: "#0D2937",
      //   error: "#AF1F24",
      //   info: "rgba(0,50,255,0.8)",
      //   success: "#3DB042",
      //   warn: "#F59332",
      //   skeleton: "#D9D9D946",
      // },
      // nca
      // {
      //   p: "#0D2937",
      //   pt: "#ffffff",
      //   a: "#EF6346",
      //   at: "#191A1F",
      //   page: "#ffffff",
      //   paget: "#0D2937",
      //   error: "#AF1F24",
      //   info: "rgba(0,50,255,0.8)",
      //   success: "#3DB042",
      //   warn: "#F59332",
      //   skeleton: "#D9D9D946",
      // },
    ],
  },
};
